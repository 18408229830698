import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const Offer = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Oferta"
        keywords={[`borkowska`, `adwokat`, `gdańsk`, `gdansk`, `kancelaria`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          {/* <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.picture.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption>adw. Agnieszka Borkowska</figcaption>
          </figure> */}
          <h3 id="oferta">Oferta</h3>
          <h4>Prawo rodzinne i&nbsp;opiekuńcze:</h4>
          <p>
            Oferta obejmuje całościowe prowadzenie sprawy, zarówno na etapie
            postępowania mediacyjnego, sądowego i&nbsp;egzekucyjnego
            z&nbsp;dopasowaniem do szczególnych potrzeb Klienta. Usługi obejmują
            w&nbsp;szczególności:
          </p>
          <ul>
            <li>
              sprawy o&nbsp;rozwód (z orzeczeniem o&nbsp;winie oraz bez
              orzekania o&nbsp;winie), w&nbsp;tym roszczenia przynależne oraz
              o&nbsp;sprawy ustanowienie separacji prawnej,
            </li>
            <li>
              sprawy o&nbsp;ustanowienie rozdzielności majątkowej, w&nbsp;tym
              z&nbsp;datą wsteczną,
            </li>
            <li>
              sprawy o&nbsp;zasądzenie, podwyższenie albo o&nbsp;obniżenie
              alimentów, w&nbsp;tym alimentów na rzecz małżonka,
            </li>
            <li>
              sprawy dotyczące władzy rodzicielskiej nad osobą małoletnią,
            </li>
            <li>
              sprawy o&nbsp;uregulowanie kontaktów i&nbsp;egzekucję kontaktów
              z&nbsp;osobą małoletnią,
            </li>
            <li>sprawy o&nbsp;podział majątku wspólnego,</li>
            <li>sprawy dotyczące wzajemnych rozliczeń konkubentów,</li>
            <li>
              sprawy dotyczące pochodzenia dziecka, w&nbsp;tym roszczenia
              przynależne, sprawy o&nbsp;zaprzeczenie macierzyństwa lub
              ojcostwa,
            </li>
            <li>
              sprawy o&nbsp;ubezwłasnowolnienie i&nbsp;przymusowe leczenie.
            </li>
          </ul>
          <h4>Prawo cywilne i&nbsp;spadkowe:</h4>
          <p>
            Kancelaria prowadzi kompleksową obsługę prawną podmiotów
            indywidualnych w&nbsp;sprawach cywilnych i&nbsp;spadkowych. Oferta
            obejmuje między innymi:
          </p>
          <ul>
            <li>
              sprawy o&nbsp;zapłatę, w&nbsp;tym sprawy o&nbsp;odszkodowanie
              i&nbsp;zadośćuczynienie,
            </li>
            <li>
              sprawy dotyczące sporów z&nbsp;instytucjami bankowymi oraz
              towarzystwami ubezpieczeń.
            </li>
            <li>sprawy związane z&nbsp;ochroną praw konsumenta,</li>
            <li>
              sprawy o&nbsp;zasiedzenie, ochronę prawa własności
              i&nbsp;posiadania, zniesienie współwłasności, ustanowienie
              służebności,
            </li>
            <li>sprawy o&nbsp;eksmisję,</li>
            <li>
              sprawy o&nbsp;stwierdzenie nabycia spadku, sprawy o&nbsp;dział
              spadku, o&nbsp;zapłatę z&nbsp;tytułu pokrycia albo uzupełnienia
              zachowku, o&nbsp;uznanie za niegodnego dziedziczenia.
            </li>
          </ul>
          <h4>Prawo gospodarcze i&nbsp;upadłościowe:</h4>
          <p>
            Kancelaria prowadzi stałą oraz doraźną pomoc prawną w&nbsp;zakresie
            obsługi podmiotów gospodarczych. Usługi obejmują
            w&nbsp;szczególności:
          </p>
          <ul>
            <li>udzielanie porad prawnych,</li>
            <li>sporządzanie oraz opiniowanie umów,</li>
            <li>sporządzanie przedsądowych wezwań do zapłaty,</li>
            <li>
              reprezentację przed organami administracji publicznej oraz sądami
              powszechnymi,
            </li>
            <li>zastępstwo w&nbsp;postępowaniu egzekucyjnym,</li>
          </ul>
          <p>
            Kancelaria zajmuje się także prowadzeniem spraw upadłościowych,
            zarówno dotyczących upadłości konsumenckich, jak i&nbsp;upadłości
            przedsiębiorców.
          </p>
          <h4>Prawo pracy i&nbsp;ubezpieczeń społecznych</h4>
          <p>
            Kancelaria udziela pomoc prawną pracownikom, pracodawcom oraz
            ubezpieczonym. Oferta obejmuje przede wszystkim:
          </p>
          <ul>
            <li>
              sporządzanie i&nbsp;opiniowanie umów o&nbsp;pracę oraz umów
              cywilnoprawnych,
            </li>
            <li>
              reprezentację przed sądami powszechnymi (sprawy
              o&nbsp;przywrócenie do pracy lub odszkodowanie, o&nbsp;ustalenie
              stosunku pracy),
            </li>
            <li>roszczenia z&nbsp;tytułu wypadku przy pracy,</li>
            <li>reprezentację przed organami rentowymi.</li>
          </ul>
          <h4>Prawo karne i&nbsp;prawo wykroczeń</h4>
          <p>
            Kancelaria oferuje pomoc prawną w&nbsp;zakresie prawa karnego
            i&nbsp;wykroczeniowego skierowaną zarówno do osób poszukujących
            obrońcy, jak i&nbsp;osób pokrzywdzonych. Oferta obejmuje kompleksowe
            prowadzenie sprawy, począwszy od etapu postępowania przygotowawczego
            i&nbsp;fazę jurysdykcyjną, po postępowanie wykonawcze. Pomoc prawna
            obejmuje między innymi:
          </p>
          <ul>
            <li>
              reprezentację oskarżonych i&nbsp;obwinionych na każdym etapie
              postępowania,
            </li>
            <li>
              reprezentację osób pokrzywdzonych przestępstwem na każdym etapie
              postępowania,
            </li>
            <li>
              reprezentację w&nbsp;postępowaniu w&nbsp;sprawach nieletnich,
            </li>
            <li>
              sporządzanie prywatnych i&nbsp;subsydiarnych aktów oskarżenia.
            </li>
          </ul>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    picture: file(relativePath: { eq: "DOR_9750popr.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const OfferExport = props => (
  <StaticQuery
    query={indexQuery}
    render={data => <Offer location={props.location} data={data} {...props} />}
  />
)
export default OfferExport
